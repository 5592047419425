
// ----------------------
// Variables
// ----------------------

// Large
$lg-height: 28px;
$lg-width: 44px;

// Medium
$md-height: 24px;
$md-width: 40px;


// Small
$sm-height: 20px;
$sm-width: 36px;


// Default size
$height: 24px;
$width: 40px;


// ----------------------
// Variants
// ----------------------

.switch__checkbox--lg {
  .switch__label {
    width: $lg-width;
    height: $lg-height;

    .switch__knob {
      width: $lg-height - 4; // 4px for 2px margin on either side
      height: $lg-height - 4;
    }
  }
}

.switch__checkbox--md {
  .switch__label {
    width: $md-width;
    height: $md-height;

    .switch__knob {
      width: $md-height - 4; // 4px for 2px margin on either side
      height: $md-height - 4;
    }
  }
}

.switch__checkbox--sm {
  .switch__label {
    width: $sm-width;
    height: $sm-height;

    .switch__knob {
      width: $sm-height - 4; // 4px for 2px margin on either side
      height: $sm-height - 4;
    }
  }
}


// ----------------------
// Styles
// ----------------------

.switch__checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.switch__label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  // width: $width;
  // height: $height;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch__label .switch__knob {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  // width: $height - 4; // 4px for 2px margin on either side
  // height: $height - 4;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch__checkbox:checked + .switch__label .switch__knob {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch__label:active .switch__knob {
  width: 60%;
}